// a type error will be raised if the shape of profilePictures does not match PeopleProfiles
import { PeopleProfiles } from '../../types';

/* Images imported below will be shown on the people page */
// images of current members
import sangbaeKim from './profile_photos/sangbae_kim.jpg';
import andrewSaloutos from './profile_photos/andrew_saloutos.jpg';
import matthewChignoli from './profile_photos/matthew_chignoli.png';
import adamJMiller from './profile_photos/adam_j_miller.jpg';
import charlesKhazoom from './profile_photos/charles_khazoom.jpeg';
import sehwanJeon from './profile_photos/sehwan_jeon.jpg';
import adiMehrotra from './profile_photos/aditya_mehrotra.jpeg';
import jennyZhang from './profile_photos/jenny_zhang.jpg';
import danielDiaz from './profile_photos/daniel_diaz.jpg';
import steveHeim from './profile_photos/steve_heim.jpg';
import hongminKim from './profile_photos/hongmin_kim.jpg';
import jessHan from './profile_photos/jess_han.jpg';
import kendrickCancio from './profile_photos/kendrick_cancio.jpg';
import hojaeLee from './profile_photos/hojae_lee.jpg';
import seungwooHong from './profile_photos/seungwoo_hong.jpeg';
import ronakRoy from './profile_photos/ronak_roy.jpg';
import davidNguyen from './profile_photos/david_nguyen.jpg';
import chrisEvagora from './profile_photos/chris_evagora.jpg';
import sharmiShah from './profile_photos/sharmi_shah.jpg';
import yunlongSong from './profile_photos/yunlong_song.jpg';
import shayanPardis from './profile_photos/shayan_pardis.jpg';
import juliaSchneider from './profile_photos/julia_schneider.jpg';
import lisaMaxwell from './profile_photos/lisa_maxwell.jpg';
import henrikHose from './profile_photos/henrik_hose.png';


// images of former members
import lauraPaez from './profile_photos/laura_paez.jpg';
import elijahSJones from './profile_photos/elijah_stanger_jones.png';
import chenhaoLi from './profile_photos/chenhao_li.jpg';
import liamAckerman from './profile_photos/liam_ackerman.jpg';
import shamelFahmi from './profile_photos/shamel_fahmi.jpg';
import yanranDing from './profile_photos/yanran_ding.jpg';
import menglongGuo from './profile_photos/menglong_guo.jpg';
import donghyunKim from './profile_photos/donghyun_kim.png';
import albertWang from './profile_photos/albert_wang.jpg';
import gerardoBledt from './profile_photos/gerardo_bledt.jpg';
import alexHattori from './profile_photos/alex_hattori.jpg';
import thomasDudzik from './profile_photos/thomas_dudzik.jpg';
import lindsayEpstein from './profile_photos/lindsay_epstein.jpg';
import haeWonPark from './profile_photos/haewon_park.jpg';
import joaoRamos from './profile_photos/joao_ramos.png';
import patrickWensing from './profile_photos/patrick_wensing.jpg';
import sangokSeok from './profile_photos/sangok_seok.jpg';
import jaredDiCarlo from './profile_photos/jared_di_carlo.png';
import quanNguyen from './profile_photos/quan_nguyen.jpg';
import sehyukYim from './profile_photos/sehyuk_yim.jpg';
import danielCarballo from './profile_photos/daniel_carballo.jpeg';
import michaelChuah from './profile_photos/michael_chuah.jpg';
import michaelFarid from './profile_photos/michael_farid.png';
import benKatz from './profile_photos/ben_katz.jpg';
import ryumaNiiyama from './profile_photos/ryuma_niiyama.jpg';
import arvindAnanthanarayanan from './profile_photos/arvind_ananthanarayanan.png';
import chihebBoussema from './profile_photos/chiheb_boussema.jpg';
import andresValenzuela from './profile_photos/andres_valenzuela.jpg';
import mattHaberland from './profile_photos/matt_haberland.jpg';
import willBosworth from './profile_photos/will_bosworth.png';
import carmenGraves from './profile_photos/carmen_graves.png';
import jongwooLee from './profile_photos/jongwoo_lee.jpg';
import johnPatrickMayo from './profile_photos/john_patrick_mayo.jpg';
import yichaoPan from './profile_photos/yichao_pan.jpg';
import josephSun from './profile_photos/joseph_sun.jpg';
import matthewPowell from './profile_photos/matthew_powell.jpeg';
import sanginPark from './profile_photos/sangin_park.jpg';
import mojtabaAzadi from './profile_photos/mojtaba_azadi.jpg';
import wyattUbellacker from './profile_photos/wyatt_ubellacker.jpg';
import kathrynEvans from './profile_photos/kathryn_evans.jpg';
import dongJinHyun from './profile_photos/dong_jin_hyun.jpg';

export const peopleProfiles: PeopleProfiles = {
  current: [
    {
      first: 'Sangbae',
      last: 'Kim',
      role: 'Professor',
      image: sangbaeKim,
      site: 'http://meche.mit.edu/people/faculty/sangbae@mit.edu',
      email: 'sangbae@mit.edu',
    },
    {
      first: 'Lisa',
      last: 'Maxwell',
      role: 'Lab Administrator',
      image: lisaMaxwell,
      site: '',
      email: 'lisa@mit.edu',
    },
    {
      first: 'Steve',
      last: 'Heim',
      role: 'Research Scientist',
      image: steveHeim,
      site: 'https://sheim.github.io/',
      email: 'sheim@mit.edu',
    },
    {
      first: 'Hongmin (David)',
      last: 'Kim',
      role: 'Postdoctoral Associate',
      image: hongminKim,
      site: '',
      email: 'davidhmk@mit.edu',
    },{
      first: 'Seungwoo',
      last: 'Hong',
      role: 'Postdoctoral Associate',
      image: seungwooHong,
      site: '',
      email: 'swhong@mit.edu',
    },
    {
      first: 'Andrew',
      last: 'SaLoutos',
      role: 'Graduate Student (Ph.D.)',
      image: andrewSaloutos,
      site: '',
      email: 'saloutos@mit.edu',
    },
    {
      first: 'Matthew',
      last: 'Chignoli',
      role: 'Graduate Student (Ph.D.)',
      image: matthewChignoli,
      site: '',
      email: 'chignoli@mit.edu',
    },
    {
      first: 'A.J.',
      last: 'Miller',
      role: 'Graduate Student (Ph.D.)',
      image: adamJMiller,
      site: '',
      email: 'ajm4@mit.edu',
    },
    {
      first: 'Charles',
      last: 'Khazoom',
      role: 'Graduate Student (Ph.D.)',
      image: charlesKhazoom,
      site: '',
      email: 'ckhaz@mit.edu',
    },
    {
      first: 'Se Hwan',
      last: 'Jeon',
      role: 'Graduate Student (Ph.D.)',
      image: sehwanJeon,
      site: '',
      email: 'sehwan@mit.edu',
    },
    {
      first: 'Ho Jae',
      last: 'Lee',
      role: 'Graduate Student (Ph.D.)',
      image: hojaeLee,
      site: 'https://hojae-io.github.io/',
      email: 'hjlee201@mit.edu',
    },
    {
      first: 'Kendrick',
      last: 'Cancio',
      role: 'Graduate Student (S.M.)',
      image: kendrickCancio,
      site: '',
      email: '',
    },
    {
      first: 'Ronak',
      last: 'Roy',
      role: 'Graduate Student (S.M.)',
      image: ronakRoy,
      site: 'ronakroy.com',
      email: '',
    },
    {
      first: 'David',
      last: 'Nguyen',
      role: 'Graduate Student (S.M.)',
      image: davidNguyen,
      site: '',
      email: 'david213@mit.edu',
    },
    {
      first: 'Christopher',
      last: 'Evagora',
      role: 'Graduate Student (M.Eng)',
      image: chrisEvagora,
      site: 'http://evagorac.github.io',
      email: '',
    },
    {
      first: 'Sharmi',
      last: 'Shah',
      role: 'Graduate Student (S.M.)',
      image: sharmiShah,
      site: '',
      email: 'sharmis@mit.edu',
    },
    {
      first: 'Henrik',
      last: 'Hose',
      role: 'Visiting Graduate Student (Ph.D.)',
      image: henrikHose,
      site: '',
      email: 'hshose@mit.edu',
    },
    {
      first: 'Shayan',
      last: 'Pardis',
      role: 'Undergraduate (UROP)',
      image: shayanPardis,
      site: 'https://shayan-p.github.io/',
      email: 'shayanp@mit.edu',
    },
    {
      first: 'Julia',
      last: 'Schneider',
      role: 'Undergraduate (UROP)',
      image: juliaSchneider,
      site: 'https://www.linkedin.com/in/juliannaeschneider/',
      email: 'juliaes@mit.edu',
    }
  ],

  former: [
    {
      first: 'Elijah',
      last: 'Stanger-Jones',
      role: 'Boston Dynamics',
      image: elijahSJones,
      site: '',
      email: 'elijahsj@mit.edu',
    },
    {
      first: 'Jess',
      last: 'Han',
      role: 'Intuitive Surgical',
      image: jessHan,
      site: 'https://www.jesshan.me/',
      email: 'jess7654@mit.edu',
    },
    {
      first: 'Yunlong',
      last: 'Song',
      role: 'Postdoctoral Associate',
      image: yunlongSong,
      site: 'https://yun-long.github.io',
      email: 'yun-long.song@outlook.com',
    },
    {
      first: 'Aditya',
      last: 'Mehrotra',
      role: 'Graduate Student (S.M.)',
      image: adiMehrotra,
      site: 'https://www.adim.io/',
      email: 'adim@mit.edu',
    },
    {
      first: 'Jenny',
      last: 'Zhang',
      role: 'Applied Intuition',
      image: jennyZhang,
      site: '',
      email: 'jenzhang@mit.edu',
    },
    {
      first: 'Chenhao',
      last: 'Li',
      role: 'Ph.D., ETH Zurich',
      image: chenhaoLi,
      site: 'https://breadli428.github.io/',
      email: 'chenhli@mit.edu',
    },
    {
      first: 'Yanran',
      last: 'Ding',
      role: 'Assistant Professor, University of Michigan',
      image: yanranDing,
      site: 'https://sites.google.com/view/yanranding/home',
      email: 'yanran@mit.edu',
    },
    {
      first: 'Shamel',
      last: 'Fahmi',
      role: 'Boston Dynamics AI Institute',
      image: shamelFahmi,
      site: 'https://www.shamelfahmi.com/',
      email: 'sfahmi@mit.edu',
    },
    {
      first: 'Daniel',
      last: 'Gonzalez-Diaz',
      role: 'Apptronik',
      image: danielDiaz,
      site: '',
      email: '',
    },
    {
      first: 'Meng',
      last: 'Guo',
      role: 'Intuitive Surgical',
      image: menglongGuo,
      site: 'https://menglongguo.github.io/website/?fbclid=IwAR3oyejZ3vjo-97G6PTEGeZ43iw_hkWNW9QIL2YIM5RrBXVPIRiOII0XIQ0',
      email: 'guomeng@mit.edu',
    },
    {
      first: 'Liam',
      last: 'Ackerman',
      role: 'Google',
      image: liamAckerman,
      site: '',
      email: 'liamack@mit.edu',
    },
    {
      first: 'Donghyun',
      last: 'Kim',
      role: 'Assistant Professor, UMass Amherst',
      image: donghyunKim,
      site: 'https://dhkim0821.github.io',
      email: '',
    },
    {
      first: 'Albert',
      last: 'Wang',
      role: 'NAVER Labs',
      image: albertWang,
      site: '',
      email: '',
    },
    {
      first: 'Gerardo',
      last: 'Bledt',
      role: 'Apptronik',
      image: gerardoBledt,
      site: '',
      email: '',
    },
    {
      first: 'Lindsay',
      last: 'Epstein',
      role: 'Apple',
      image: lindsayEpstein,
      site: '',
      email: '',
    },
    {
      first: 'Thomas',
      last: 'Dudzik',
      role: 'HFT Firm',
      image: thomasDudzik,
      site: 'https://tomdudzik.com/',
      email: '',
    },
    {
      first: 'Laura',
      last: 'Paez',
      role: 'Research Scientist, Nestle',
      image: lauraPaez,
      site: 'https://km-robota.com/ppl/2lp.html',
      email: 'laura.paez@km-robota.com',
    },
    {
      first: 'Alex',
      last: 'Hattori',
      role: 'Kitty Hawk',
      image: alexHattori,
      site: 'https://alexhattori.wordpress.com/about/',
      email: '',
    },
    {
      first: 'Hae Won',
      last: 'Park',
      role: 'Assistant Professor, KAIST',
      image: haeWonPark,
      site: 'https://www.dynamicrobot.kaist.ac.kr/',
      email: '',
    },
    {
      first: 'Joao',
      last: 'Ramos',
      role: 'Assistant Professor, UIUC',
      image: joaoRamos,
      site: '',
      email: '',
    },
    {
      first: 'Patrick',
      last: 'Wensing',
      role: 'Assistant Professor, U of Notre Dame',
      image: patrickWensing,
      site: 'https://sites.nd.edu/pwensing/',
      email: '',
    },
    {
      first: 'Sangok',
      last: 'Seok',
      role: 'CEO, NAVER Labs',
      image: sangokSeok,
      site: '',
      email: '',
    },
    {
      first: 'Jared',
      last: 'Di Carlo',
      role: 'Boston Dynamics',
      image: jaredDiCarlo,
      site: '',
      email: '',
    },
    {
      first: 'Quan',
      last: 'Nguyen',
      role: 'Assistant Professor, USC',
      image: quanNguyen,
      site: '',
      email: '',
    },
    {
      first: 'Sehyuk',
      last: 'Yim',
      role: 'Senior Research Scientist, KIST',
      image: sehyukYim,
      site: 'https://scholar.google.com/citations?user=TT9NF40AAAAJ&hl=en',
      email: '',
    },
    {
      first: 'Daniel',
      last: 'Carballo',
      role: 'Co-founder, Encora Therapeutics',
      image: danielCarballo,
      site: 'https://scholar.google.com/citations?user=TT9NF40AAAAJ&hl=en',
      email: '',
    },
    {
      first: 'Michael',
      last: 'Chuah',
      role: 'Research Scientist, I2R A*STAR Singapore',
      image: michaelChuah,
      site: 'http://pixelhub.me/mcx',
      email: '',
    },
    {
      first: 'Michael',
      last: 'Farid',
      role: 'Co-Founder, CEO at Spyce',
      image: michaelFarid,
      site: '',
      email: '',
    },
    {
      first: 'Ben',
      last: 'Katz',
      role: 'Mechanical Engineer, Boston Dynamics',
      image: benKatz,
      site: 'http://build-its.blogspot.com/',
      email: '',
    },
    {
      first: 'Ryuma',
      last: 'Niiyama',
      role: 'Assistant Professor, University of Tokyo',
      image: ryumaNiiyama,
      site: '',
      email: '',
    },
    {
      first: 'Arvind',
      last: 'Ananthanarayanan',
      role: 'Technical PM, Facebook',
      image: arvindAnanthanarayanan,
      site: '',
      email: '',
    },
    {
      first: 'Chiheb',
      last: 'Boussema',
      role: 'Visiting Researcher from EPFL',
      image: chihebBoussema,
      site: 'https://www.linkedin.com/in/chiheb-boussema-9baab295/',
      email: '',
    },
    {
      first: 'Andrés',
      last: 'Valenzuela',
      role: 'MathWorks',
      image: andresValenzuela,
      site: '',
      email: '',
    },
    {
      first: 'Matt',
      last: 'Haberland',
      role: 'Assistant Professor, Cal Poly',
      image: mattHaberland,
      site: 'https://mdhaber.wixsite.com/home',
      email: '',
    },
    {
      first: 'Will',
      last: 'Bosworth',
      role: 'Aurora Flight',
      image: willBosworth,
      site: 'https://mdhaber.wixsite.com/home',
      email: '',
    },
    {
      first: 'Carmen',
      last: 'Graves',
      role: 'Senior Mechanical Engineer, Voxel8',
      image: carmenGraves,
      site: '',
      email: '',
    },
    {
      first: 'Jongwoo',
      last: 'Lee',
      role: 'Graduate student, MIT',
      image: jongwooLee,
      site: '',
      email: '',
    },
    {
      first: 'John',
      last: 'Patrick Mayo',
      role: 'Robotics Mechanical Engineer, JPL',
      image: johnPatrickMayo,
      site: '',
      email: '',
    },
    {
      first: 'Yichao',
      last: 'Pan',
      role: 'Energid',
      image: yichaoPan,
      site: '',
      email: '',
    },
    {
      first: 'Xu (Joseph)',
      last: 'Sun',
      role: 'Mechanical Engineer, Microsoft',
      image: josephSun,
      site: '',
      email: '',
    },
    {
      first: 'Matthew',
      last: 'Powell',
      role: 'Houston Mechatronics',
      image: matthewPowell,
      site: '',
      email: '',
    },
    {
      first: 'Sangin',
      last: 'Park',
      role: 'Hyundai Motor',
      image: sanginPark,
      site: '',
      email: '',
    },
    {
      first: 'Mojtaba',
      last: 'Azadi',
      role: 'Assistant Professor, SFSU',
      image: mojtabaAzadi,
      site: '',
      email: '',
    },
    {
      first: 'Wyatt',
      last: 'Ubellacker',
      role: 'Robot Technologist, JPL',
      image: wyattUbellacker,
      site: '',
      email: '',
    },
    {
      first: 'Kathryn',
      last: 'Evans',
      role: 'Factual Inc.',
      image: kathrynEvans,
      site: '',
      email: '',
    },
    {
      first: 'Dong Jin',
      last: 'Hyun',
      role: 'Senior Research Engineer, Hyundai Motor',
      image: dongJinHyun,
      site: '',
      email: '',
    },
  ],
};
